import { Camera, Mesh, Scene, Texture, WebGLRenderTarget } from 'three';

import { noop } from '@resn/gozer-misc';

// import { ticks } from 'gozer-utils/misc/ticker';

const DEBUG = true;
const MAX_RENDER_DURATION_THRESHOLD = 8;
const LONG_RENDER_DURATION_THRESHOLD = 8;
const REST_DURATION = 100;

const log = DEBUG
    ? (...args) => {
          console.info(...args);
      }
    : noop;

const wait = async (duration) => {
    return new Promise((resolve) => {
        setTimeout(resolve, duration);
    });
};

// const onUpdateTexture = (tex) => {
//     if (prerendering) {
//         uploadedTextures.push(tex.image.src);
//     }
// };

// THREE.Texture.prototype = Object.defineProperty(THREE.Texture.prototype, 'onUpdate', {
//     get: function onUpdate() {
//         return this._onUpdate || onUpdateTexture;
//     },
//     set: function onUpdate(value) {
//         if (value !== null) {
//             this._onUpdate = value;
//         }
//     }
// });

const prerenderTarget = new WebGLRenderTarget(1, 1);
const scene = new Scene();
const camera = new Camera();
// let prerendering = false;
let uploadedTextures = [];

const prerender = async (id, renderer, root) => {
    if (DEBUG) {
        log(`prerender ${id}`);
    }

    const start = Date.now();

    // prerendering = true;

    const meshes = [];
    root.traverse((object3d) => {
        if (object3d instanceof Mesh) {
            meshes.push(object3d);
        }
    });

    let numSlow = 0;
    let duration = 0;
    for (let i = 0; i < meshes.length; i += 1) {
        const renderDuration = prerenderMesh(id, renderer, meshes[i]);
        duration += renderDuration;

        if (renderDuration >= LONG_RENDER_DURATION_THRESHOLD) {
            numSlow += 1;
        }

        if (duration >= MAX_RENDER_DURATION_THRESHOLD) {
            duration = 0;
            await wait(REST_DURATION);
        }
    }

    // prerendering = false;

    if (DEBUG) {
        log(
            `prerender complete - ${id} - ${numSlow}/${
                meshes.length
            } meshes need optimization, duration: ${Date.now() - start}ms`
        );
    }
};

const prerenderMesh = (id, renderer, mesh) => {
    uploadedTextures = [];

    renderer.setRenderTarget(prerenderTarget);
    renderer.clear();

    const numProgramsBefore = renderer.info.programs.length;
    const numGeometriesBefore = renderer.info.memory.geometries;

    const wasFrustumCulled = mesh.frustumCulled;
    const wasVisble = mesh.visible;
    mesh.frustumCulled = false;
    mesh.visible = true;
    const parent = mesh.parent;
    scene.add(mesh);

    const start = Date.now();
    renderer.render(scene, camera);
    renderer.setRenderTarget(null);
    const delta = Date.now() - start;

    mesh.frustumCulled = wasFrustumCulled;
    mesh.visible = wasVisble;
    if (parent) {
        parent.add(mesh);
    }

    if (DEBUG && delta > LONG_RENDER_DURATION_THRESHOLD) {
        const info = {
            id,
        };

        if (mesh.name) {
            info.meshName = mesh.name;
        }

        info.geometrySize = mesh.geometry.index.count;

        if (mesh.material.name) {
            info.materialName = mesh.material.name;
        }

        info.materialType = mesh.material.type;

        if (mesh.material.uniforms) {
            Object.keys(mesh.material.uniforms).forEach((key) => {
                if (mesh.material.uniforms[key].value instanceof Texture) {
                    const tex = mesh.material.uniforms[key].value;
                    info[`uniform-${key}`] = {
                        src: tex.image?.src || tex.name || '[unknown]',
                        width: tex.image?.width,
                        height: tex.image?.height,
                    };
                }
            });
        }

        const numProgramsAfter = renderer.info.programs.length;
        if (numProgramsAfter !== numProgramsBefore) {
            info.uploadedPrograms = numProgramsAfter - numProgramsBefore;
        }

        const numGeometriesAfter = renderer.info.memory.geometries;
        if (numGeometriesAfter !== numGeometriesBefore) {
            info.uploadedGeometries = numGeometriesAfter - numGeometriesBefore;
        }

        if (uploadedTextures.length) {
            info.uploadedTextures = uploadedTextures.concat();
        }

        // log(`rendering mesh took long (${delta}ms)`, info);
    }

    return delta;
};

export { prerender, prerenderMesh };
