import { defineComponent, inject, onUnmounted, provide } from 'vue';

import { useRenderer } from '@resn/gozer-vue';

import { prerender } from '~/libs/three/prerender';

export const ThreePrerenderKey = Symbol('GlobalAssets');

export const useThreePrerender = () => {
    const provider = inject(ThreePrerenderKey);

    if (!provider) {
        throw new Error('ThreePrerender provider not found');
    }

    return provider;
};

export const ThreePrerender = defineComponent({
    props: {},

    async setup() {
        let scenes = [];

        const { renderer } = useRenderer();

        const addSceneToPrerender = ({
            scene,
            index = Infinity,
            id = 'Prerender default id',
        } = {}) => {
            scenes.push({ scene, index, id });
            scenes.sort((a, b) => a.index - b.index);
        };

        const removeSceneFromPrerender = (fn) => {
            const item = scenes.find((item) => item.fn === fn);
            scenes.splice(item, 1);
        };

        const prerenderAll = async () => {
            return Promise.all(scenes.map((item) => prerender(item.id, renderer, item.scene)));
        };

        const reset = () => {
            scenes = [];
        };

        const provider = {
            reset,
            prerenderAll,
            addSceneToPrerender,
            removeSceneFromPrerender,
        };

        onUnmounted(reset);

        provide(ThreePrerenderKey, provider);
    },

    render() {
        if (this.$slots.default) {
            return this.$slots.default();
        }

        return null;
    },
});
